import React from 'react';
import LogoSVG from "../assets/Logo.svg"
import Container from "react-bootstrap/cjs/Container"
import Layout from "./layout"

export default function ContentWrapper(props) {
  const {children, title, category} = props;

  return (
    <Layout title={title} category={category}>
      <div id='bglogo' aria-hidden={true}>
        <img className='img-fluid' src={LogoSVG} width='800' height='600' alt='Background Logo' />
      </div>
      <Container id='mainContent' as='main'>
        {children}
      </Container>
    </Layout>
  );
}
